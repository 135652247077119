<template>
  <div class="contents p-3">
    <div v-if="userContents.data.length > 0">
      <b-row no-gutters align-v="stretch">
        <b-col
          class="content-cont"
          cols="12"
          md="6"
          lg="6"
          xl="4"
          v-for="content in userContents.data"
          :key="content.item.id">
          <div class="overlay">
            <b-icon
              class="delete-icon"
              icon="trash"
              title="Delete"
              @click="deleteContent(content)"
            ></b-icon>
            <b-icon
              class="download-icon"
              icon="download"
              title="Download"
              @click="downloadContent(content)"
            ></b-icon>
          </div>
          <div
            class="content-wrapper"
            v-if="content.item.type === 'image'"
            :style="{height: `${colHeight}px`}"
            >
            <img
              class="img-fluid img-thumb"
              :src="content.item.url"
              alt="User Content"
              :style="{height: `${colHeight}px`}"
            />
          </div>

          <div
            class="content-wrapper video-player-col"
            v-if="content.item.type === 'video'"
            :style="{height: `${colHeight}px`}">
            <vue-plyr>
              <video
                :src="content.item.url"
                :style="{height: `${colHeight}px`}">
                <source
                  :src="content.item.url"
                  type="video/mp4"
                  size="720"
                />
                <source
                  :src="content.item.url"
                  type="video/mp4"
                  size="1080"
                />
              </video>
            </vue-plyr>
          </div>
        </b-col>
      </b-row>

      <div class="text-center" v-if="currentPage < lastPage">
        <b-button
          class="btn-h3-green"
          type="button"
          @click="loadMoreContents">
          Load More
        </b-button>
      </div>
    </div>

    <div v-else class="no-content">
      <p>
        No contents available
      </p>
    </div>

    <!-- Dialog -->
    <v-dialog />

    <AppLoader :isLoading="loading" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    data() {
      return {
        loading     : false,
        colWidth    : 0,
        colHeight   : 0,
        currentPage : 1,
      }
    },
    computed : {
      ...mapGetters({
        user         : 'user/user',
        userContents : 'profile/userContents',
      }),
      lastPage() {
        return this.userContents.lastPage;
      },
    },
    components : {
      'AppLoader' : () => import('@/components/layout/AppLoader'),
    },
    methods : {

      /**
       * Load More User Contents
       */
      loadMoreContents() {
        this.currentPage++;
        this.$store.dispatch('profile/getUserContents', this.currentPage);
      },

      /**
       * Delete User Content
       * @param data
       */
      deleteContent(data) {
        this.$modal.show('dialog', {
          title   : 'Delete Content',
          text    : 'Are you sure you want to delete this content?',
          buttons : [ {
            title   : 'Yes',
            handler : () => {
              this.loading = true;
              this.$modal.hide('dialog');
              this.$http.delete('api/media/ucc/delete', {
                data : {
                  userId : this.user.user_id,
                  id     : data.item.id,
                },
              }).then(() => {
                this.$notify({
                  group : 'notif',
                  title : 'Success',
                  type  : 'success',
                  text  : 'Deleted image successfully!',
                });
                this.currentPage = 1;
                this.$store.dispatch(
                  'profile/getUserContents', 1);
              }).catch(() => {
                this.$notify({
                  group : 'notif',
                  title : 'Failed',
                  type  : 'error',
                  text  : 'Oops! Something went wrong!',
                });
              }).finally(() => {
                this.loading = false;
              });
            },
          }, {
            title : 'No',
          } ],
        });
      },

      /**
       * Download Content
       * @param data
       */
      downloadContent(data) {
        this.loading = true;
        this.$http.get('api/ucc/downloadUrl', {
          params : {
            uccId : data.item.id,
          },
        }).then(response => {
          const xhr = new XMLHttpRequest();
          xhr.open("GET", response.data.url, true);
          xhr.responseType = 'blob';
          xhr.onload = () => {
            const a = document.createElement('a');
            const url = window.URL.createObjectURL(xhr.response);
            document.body.appendChild(a);
            a.download = data.item.filename;
            a.href = url;
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            this.loading = false;
          };
          xhr.send();
        })
      },

      /**
       * Get Column's Width
       */
      getColumnWidth() {
        this.$nextTick(() => {
          const $colId = this.$el.getElementsByClassName('content-wrapper')[0];
          if ($colId) {
            this.colWidth = $colId.clientWidth;
            this.colHeight = $colId.clientWidth;
          }
        });
      },
    },
    mounted() {
      this.$store.dispatch('profile/getUserContents', 1);
      this.getColumnWidth();
    },
    updated() {
      this.getColumnWidth();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .contents {
    background-color: rgba(233, 64, 22, 0.7) !important;
    box-shadow: 3px 10px 10px rgba(0,0,0,0.5);
  }

  .content-cont {
    .overlay {
      display: none;
      position: absolute;
      top: 1rem;
      right: 1rem;
      z-index: 1;
    }
    &:hover {
      .overlay {
        display: grid;
      }
    }
  }

  .content-wrapper {
    overflow: hidden;
    padding: 0;
    margin: 0.5rem;
    flex: 0 0 23.5%;
    border-image: linear-gradient(to left, #743ad5, #d53a9d);
    border-image-slice: 1;
    border-style: solid;
    border-width: 4px;

    .img-thumb {
      overflow: hidden;
      width: 100%;
      // border-radius: 3px;
      // cursor: pointer;
      object-fit: cover;
      transition: all 0.4s ease;
    }

    &:hover {
      .img-thumb {
        -moz-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
  }

  .delete-icon, .download-icon {
    margin: 0.25rem;
    padding: 0.4rem;
    background-color: $primary-color;
    border-radius: 1rem;
    color: #fff;
    font-size: 1.5em;
    opacity: 0.75;
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  .no-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30vh;
    
    p {
      margin-bottom: 0;
      color: #fff;
      text-align: center;
    }
  }

</style>